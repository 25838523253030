import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const Section = loadable(() =>
    import('/src/components/Structure/Section/Section')
)
const VideoPlayer = loadable(() => import('/src/components/Media/VideoPlayer'))

const Video = ({ backgroundColour, video }) => {
    return (
        <Section variant={backgroundColour} className={'c-video-block'}>
            <div className="row">
                <div className="col-lg-6 offset-lg-3">
                    <VideoPlayer data={video} />
                </div>
            </div>
        </Section>
    )
}

Video.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']),
    video: PropTypes.string
}

Video.defaultProps = {
    backgroundColour: 'light',
    video: 'https://protect-line-marketing.wistia.com/medias/sce6ad8azy'
}

export default Video

export const query = graphql`
    fragment VideoForPageBuilder on WpPage_Acfpagebuilder_Layouts_Video {
        fieldGroupName
        backgroundColour
        video
    }
`
